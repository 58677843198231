<template>
  <div class="dataSummary">
    <div class="flex-box">
      <div>
        <span style="margin-right:20px">年度</span>
        <el-date-picker v-model="queryYear"
          value-format="yyyy"
          type="year"
          @change="loadQuestionData"
          placeholder="请选择年度">
        </el-date-picker>
      </div>
      <el-button type="primary"
        icon='el-icon-download'
        @click="questionnireTypeVisible=true">导 出</el-button>
    </div>
    <el-table :data="scoreList">
      <el-table-column label="年度"
        align="center"
        width="100px">
        <template slot-scope="scope">
          {{scope.row.nd}}年
        </template>
      </el-table-column>
      <el-table-column label="姓名"
        align="center"
        prop="mname"></el-table-column>
      <el-table-column label="教师评分"
        align="center">
        <template slot-scope="scope">
          <el-link @click="scoreDetail(scope.row,1)">{{scope.row.teaScore}} <i class="el-icon-d-arrow-right"></i></el-link>
        </template>
      </el-table-column>
      <el-table-column label="家长评分"
        align="center">
        <template slot-scope="scope">
          <el-link @click="scoreDetail(scope.row,2)">{{scope.row.stuScore}}<i class="el-icon-d-arrow-right"></i></el-link>
        </template>
      </el-table-column>
      <el-table-column label="业务评分"
        align="center">
        <template slot-scope="scope">
          <el-link @click="scoreDetail(scope.row,0)">{{scope.row.ywScore}}<i class="el-icon-d-arrow-right"></i></el-link>
        </template>
      </el-table-column>
      <el-table-column label="年度考核总分"
        align="center"
        prop="allScore"></el-table-column>
    </el-table>
    <el-dialog width="600px"
      title="导出问卷"
      :visible.sync="questionnireTypeVisible">
	  <el-form>
		<el-form-item label="问卷类型">
		  <el-select v-model="queryType"
					 placeholder="请选择问卷类型">
			<el-option label="教师问卷详情"
					   value="1"></el-option>
			<el-option label="家长问卷详情"
					   value="2"></el-option>
			<el-option label="业务评测详情"
					   value="3"></el-option>
			<el-option label="汇总数据"
					   value="4"></el-option>
		  </el-select>
		</el-form-item>
	  </el-form>
      <div class="button-box">
        <el-button @click="exportFile"
          type="primary">导出</el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "dataSummary",
  data() {
    return {
      queryYear: "",
      scoreList: [],
      queryType: "",
      questionnireTypeVisible: false,
    };
  },
  created() {
    this.defaultQueryYear();
    this.loadQuestionData();
  },
  methods: {
    loadQuestionData() {
      const that = this;
      let data = { nd: this.queryYear };
      this.$post("/base/queryNdResult.do", data)
        .then((res) => {
          that.scoreList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    defaultQueryYear() {
      let date = new Date();
      this.queryYear = String(date.getFullYear());
    },
    scoreDetail(row, type) {
      //type：1教师2学生0业务
      this.$router.push({
        name: "scoreDetails",
        query: { yearId:row.nd, managerId:row.managerid, userName:row.mname, type },
      });
    },
    exportFile() {
      const that = this;
      let apiUrl =  `/base/exportDetailList.do?nd=${this.queryYear}&type=${this.queryType}`
	  if(this.queryType==='3'){
		apiUrl = `/base/exportYwResult.do?nd=${this.queryYear}`
	  }
	  if(this.queryType==='4'){
		apiUrl = `/base/exportNdResult.do?nd=${this.queryYear}`
	  }
      this.$postFile(apiUrl)
        .then((res) => {
          let type='application/vnd.ms-excel;'
		  if(that.queryType==='3'){
		    type='application/zip;'
		  }
          const blob = new Blob([res.data], {type});
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          let fileName = `${this.queryYear}年度教师问卷详情`
		  if(that.queryType==='2'){
			fileName = `${this.queryYear}年度家长问卷详情`
		  }
		  if(that.queryType==='3'){
			fileName = `${this.queryYear}年度业务评测详情`
		  }
		  if(that.queryType==='4'){
			fileName = `${this.queryYear}年度问卷汇总`
		  }
          a.download = fileName;
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.input-box {
  width: 217px;
  padding: 0 15px;
}
.button-box {
  margin-top: 20px;
}
.flex-box {
  margin: 0 10px;
  display: flex;
  justify-content: space-between;
}
</style>
